

.web-APP-card {
  background-image: url(./../../../assets/icons/cmf_linea/card-black.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  height: 160px;
  overflow: hidden;
  position: relative;
  width: 282px;
  cursor: pointer;
}
.caja-clickeable:hover {
  cursor: pointer; /* Cambia el cursor a 'pointer' cuando el mouse está encima */
}

.logotype-instance {
  height: 24px !important;
  left: 16px !important;
  position: absolute !important;
  top: 16px !important;
  width: 64px !important;
}

.info {
  align-items: flex-start;
  display: inline-flex;
  gap: 8px;
  left: 116px;
  padding: 16px;
  position: absolute;
  top: 0;
}

.spinnerCard {
  height: 38px;
  position: relative;
  width: 38px;
}

.overlap-group {
  /* background-image: url(./segment.svg); */
  background-size: 100% 100%;
  border: 4px solid;
  border-color: #d0d5dd29;
  border-radius: 14.25px;
  height: 28px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 28px;
}

.div {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.text-wrapper {
  color: #ffffff;
  font-family: var(--heading-s-medium-font-family);
  font-size: var(--heading-s-medium-font-size);
  font-style: var(--heading-s-medium-font-style);
  font-weight: var(--heading-s-medium-font-weight);
  letter-spacing: var(--heading-s-medium-letter-spacing);
  line-height: var(--heading-s-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-2 {
  color: #ffffffcc;
  font-family: var(--detail-s-font-family);
  font-size: var(--detail-s-font-size);
  font-style: var(--detail-s-font-style);
  font-weight: var(--detail-s-font-weight);
  letter-spacing: var(--detail-s-letter-spacing);
  line-height: var(--detail-s-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.info-2 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  left: -1px;
  padding: 16px;
  position: absolute;
  top: 106px;
  width: 282px;
}

.text-wrapper-3 {
  color: #ffffffcc;
  font-family: var(--detail-m-font-family);
  font-size: var(--detail-m-font-size);
  font-style: var(--detail-m-font-style);
  font-weight: var(--detail-m-font-weight);
  letter-spacing: var(--detail-m-letter-spacing);
  line-height: var(--detail-m-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-4 {
  color: #ffffff;
  font-family: var(--heading-XS-medium-font-family);
  font-size: var(--heading-XS-medium-font-size);
  font-style: var(--heading-XS-medium-font-style);
  font-weight: var(--heading-XS-medium-font-weight);
  letter-spacing: var(--heading-XS-medium-letter-spacing);
  line-height: var(--heading-XS-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.web-APP-card-2 {
  background-image: url(./../../../assets/icons/cmf_linea/card-black1.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  height: 160px;
  overflow: hidden;
  position: relative;
  width: 282px;
}

.overlap-group-2 {
  /* background-image: url(./image.svg); */
  background-size: 100% 100%;
  border: 4px solid;
  border-color: #d0d5dd29;
  border-radius: 14.25px;
  height: 28px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 28px;
}

.web-APP-card-3 {
  background-image: url(./../../../assets/icons/cmf_linea/card-black2.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 20px;
  height: 160px;
  overflow: hidden;
  position: relative;
  width: 282px;
}

.overlap-group-3 {
  /* background-image: url(./segment-2.svg); */
  background-size: 100% 100%;
  border: 4px solid;
  border-color: #d0d5dd29;
  border-radius: 14.25px;
  height: 28px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 28px;
}
