@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .shadow-custom {
    --shadow-color: #d6d6d6;
    --shadow-x: 4px;
    --shadow-y: 3px;
    --shadow-blur: 7px;
    box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur)
      var(--shadow-color);
  }
}

@import '~antd/dist/antd.css';
:root {
  --linear-bg: transparent linear-gradient(90deg, #399001 0%, #53a600 100%) 0%
    0% no-repeat;
  --light-green: #53a600;
  --color-primario: #3dae2b;
  --color-primary-main: #13a438;
  --color-primario-oscuro: #26951f;
  --color-primario-dark: #0e9a32;
  --color-primario-opacidad: #d9f0df;
  --fondo-blanco: #ffffff;
  --color-secundario-fondo: #f0f2f5;
  --color-secundario-oscuro-fondo: #afbcd2;
  --fondo-negro: #000000;
  --color-primary-black: #000000;
  --fondo-negro-secundario: #363434;
  --fuente-primara: Arial, Helvetica, sans-serif;
  --color-primario-warn: #fe2828;
  --color-primary-grey: #f1f1f1;
  --color-secundario-Grey: #7b7d7d;
  --color-gray-dark: #545454;
  --color-primary-blue: #0058b8;
  --color-primary-grey-dark: #707070;
  --color-primary-grey-light: #d5d5d5;
  --unnamed-color-c8c5c5: #c8c5c5;
  --unnamed-color-b7b6b6: #b7b6b6;
  --unnamed-color-5a5a5a: #5a5a5a;
  --unamed-color-f5f5f5: #f5f5f5;
  --transparent-color: #00000000;
}

.dot-bricks {
  position: relative;
  top: 8px;
  left: -9999px;
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background-color: #011e41;
  color: #011e41;
  box-shadow: 9991px -16px 0 0 #011e41, 9991px 0 0 0 #011e41,
    10007px 0 0 0 #011e41, 9991px 16px 0 0 #011e41;
  animation: dot-bricks 2s infinite ease;
}

@keyframes dot-bricks {
  0% {
    box-shadow: 9991px -16px 0 0 #011e41, 9991px 0 0 0 #011e41,
      10007px 0 0 0 #011e41, 9991px -16px 0 0 #011e41;
  }
  8.333% {
    box-shadow: 10007px -16px 0 0 #011e41, 9991px 0 0 0 #011e41,
      10007px 0 0 0 #011e41, 9991px -16px 0 0 #011e41;
  }
  16.667% {
    box-shadow: 10007px -16px 0 0 #011e41, 9991px -16px 0 0 #011e41,
      10007px 0 0 0 #011e41, 9991px -16px 0 0 #011e41;
  }
  25% {
    box-shadow: 10007px -16px 0 0 #011e41, 9991px -16px 0 0 #011e41,
      9991px 0 0 0 #011e41, 9991px -16px 0 0 #011e41;
  }
  33.333% {
    box-shadow: 10007px 0 0 0 #93d500, 9991px -16px 0 0 #93d500,
      9991px 0 0 0 #93d500, 9991px -16px 0 0 #011e41;
  }
  41.667% {
    box-shadow: 10007px 0 0 0 #93d500, 10007px -16px 0 0 #93d500,
      9991px 0 0 0 #93d500, 9991px -16px 0 0 #011e41;
  }
  50% {
    box-shadow: 10007px 0 0 0 #93d500, 10007px -16px 0 0 #93d500,
      9991px -16px 0 0 #93d500, 9991px -16px 0 0 #011e41;
  }
  58.333% {
    box-shadow: 9991px 0 0 0 #93d500, 10007px -16px 0 0 #93d500,
      9991px -16px 0 0 #93d500, 9991px -16px 0 0 #011e41;
  }
  66.666% {
    box-shadow: 9991px 0 0 0 #54565a, 10007px 0 0 0 #54565a,
      9991px -16px 0 0 #54565a, 9991px -16px 0 0 #011e41;
  }
  75% {
    box-shadow: 9991px 0 0 0 #54565a, 10007px 0 0 0 #54565a,
      10007px -16px 0 0 #54565a, 9991px -16px 0 0 #011e41;
  }
  83.333% {
    box-shadow: 9991px -16px 0 0 #026e18, 10007px 0 0 0 #026e18,
      10007px -16px 0 0 #026e18, 9991px -16px 0 0 #011e41;
  }
  91.667% {
    box-shadow: 9991px -16px 0 0 #026e18, 9991px 0 0 0 #026e18,
      10007px -16px 0 0 #026e18, 9991px -16px 0 0 #011e41;
  }
  100% {
    box-shadow: 9991px -16px 0 0 #026e18, 9991px 0 0 0 #026e18,
      10007px 0 0 0 #026e18, 9991px -16px 0 0 #011e41;
  }
}

body {
  margin: 0px;
  padding: 0px;
  font-family: Gilroy-Light, 'Helvetica', 'Red Hat Text', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: normal !important;
  font-variant: normal !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #26951f !important;
  text-shadow: 0 0 0.25px currentcolor;
}
.mainScreenBg {
  background: url('./assets/images/onboardingCredito/Enmascarar\ grupo\ 9.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.veifyScreenBg {
  background: url('./assets/images/onboardingCredito/Enmascarar\ grupo\ 9@2x.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.smallScreenBg {
  background: url('./assets/images//onboardingCredito/Enmascarar\ grupo\ 1@2x.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.smallVerifiedScreenBg {
  background: linear-gradient(
    180deg,
    rgba(0, 54, 10, 1) 0%,
    rgba(1, 111, 23, 1) 65%
  );
}
.greenBtnBg {
  background: linear-gradient(
    90deg,
    rgba(57, 144, 1, 1) 0%,
    rgba(83, 166, 0, 1) 35%
  );
}
.waterMark {
  rotate: -20deg;
}
.css-1gnty12-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0px !important;
  top: 10px;
}
.screenCustomRejected {
  background: radial-gradient(
    circle,
    rgba(57, 144, 1, 1) 0%,
    rgba(159, 231, 55, 1) 73%
  );
}

@media screen and (min-width: 1000px) {
  .desktop-only-title {
    padding-left: 5rem !important;
  }
}

@media (max-width: 390px) {
  .desktop-only-title {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .veifyScreenBg {
    background: linear-gradient(
      177deg,
      rgba(2, 60, 17, 1) 0%,
      rgba(1, 111, 23, 1) 70%
    );
  }
}
.notification {
  box-sizing: border-box;
  padding: 24px 24px 24px 58px;
  border-radius: 16px;
  color: #fff;
  background-color: #ccc;
  box-shadow: 0 0 12px #999;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 1;
  margin-top: 15px;
}

.notification-info {
  background-color: #0050ab;
}

.notification-info:before {
  content: url('./assets/images/cmf_linea/info.svg');
}

.notification-success {
  background-color: #247e00;
}

.notification-success:before {
  content: url('./assets/images/cmf_linea/success.svg');
}

.notification-warning {
  background-color: #ffe600;
  color: #101828;
  font-size: 1em;
  line-height: 22px;
  letter-spacing: -0.36px;
  font-weight: 500;
}

.notification-warning:before {
  content: url('./assets/images/cmf_linea/warning.svg');
  color: #101828;
  font-size: 1em;
  line-height: 22px;
  letter-spacing: -0.36px;
  font-weight: 500;
}

.notification-error {
  background-color: #d52007;
}

.notification-error:before {
  content: url('./assets/images/cmf_linea/errorNoti.svg');
}
