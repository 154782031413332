/* modal de mantenimiento */
.modal__banner {
  width: 100%;
}

.modal__banner h1 {
  text-align: justify;
  font: normal normal 800 20px/25px Gilroy, sans-serif;
  padding: 24px 5px;
  width: 100%;
  margin-bottom: 0
}

.modal__banner .icon_img {
  padding: 5px 48%;
  font-size: 44px;
  color: var(--color-primary-main);
}