.container {
    width: 90%;
    max-width: 600px;
    padding: 20px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: auto;
}


main h1 {
    color: #13A438;
    font-size: 1.8em;
}

main p {
    margin: 15px 0;
    font-size: 1em;
    color: #333;
}

.button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #13A438;
    color: #FFFFFF;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 20px;
}


@media (orientation: landscape) {
    .container {
        max-width: 80%;
    }

    main h1 {
        font-size: 2em;
    }

    .button {
        padding: 12px 24px;
    }

}