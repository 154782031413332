.mainContainer {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 25%;
  grid-template-rows: 1fr min-content;
  font-family: 'Red Hat Text', sans-serif;
  background-color: #f2f4f7;
}

.firstSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  grid-column: 1/3;
  grid-row: 1/2;
  padding: 40px;
  background-color: #f2f4f7;
}

.firstSection > h1 {
  width: 100%;
  font-size: 35px;
  text-align: center;
  color: #011e41;
  font-weight: bold;
  line-height: 2.5rem;
  margin: 10px 0px 0px 0px;
}

.firstSection > h2 {
  width: 100%;
  font-size: 20px;
  text-align: center;
  color: #011e41;
  font-weight: 500;
  margin: 20px 0px 20px 0px;
}

.firstSection > p {
  font-size: 16px;
  text-align: center;
  color: #000000;
  max-width: 1200px;
}

.firstSection > button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 320px;
  height: 45px;
  margin-top: 40px;
  cursor: pointer;
  background: transparent linear-gradient(270deg, #53a600 0%, #399001 100%) 0%
    0% no-repeat padding-box;
  border-radius: 38px;
  border: none;
}

.firstSection > button > span {
  color: #ffffff;
  font-size: 18px;
}

.secondSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: fit-content;
  padding: 0px 10px 40px 10px;
  background-color: #f2f4f7;
  grid-column: 1/2;
  grid-row: 2/3;
}

.secondSection > .mainText {
  width: 100%;
  max-width: 540px;
  font-size: 12px;
  text-align: center;
  margin: auto 0px 0px 0px;
}

.secondSection > .contactElements {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.contactContainer {
  display: flex;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  margin: 5px 10px;
}

.contactContainer > img {
  height: 15px;
  width: 15px;
  object-fit: contain;
  margin: 0px 5px 0px 0px;
}

.contactContainer > span {
  font-size: 14px;
}

.thirdSection {
  grid-column: 2/3;
  grid-row: 1/3;
  align-self: flex-end;
  width: 100%;
}

.thirdSection > img {
  height: 100%;
  max-height: 320px;
  width: 100%;
  object-fit: contain;
}

.greenLine {
  grid-row: 2/3;
  grid-column: 1/3;
  display: flex;
  height: 20px;
  width: 100%;
  background-color: green;
  align-self: flex-end;
}

.storeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.storeSection > p {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}
.disponible {
  font-size: 14px !important;
  font-weight: 400 !important;
}
.iconContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.divContains {
  margin: 5px;
  width: 30%;
}

@media only screen and (min-width: 768px) {
  .firstSection {
    padding: 20px 10px;
  }
  .firstSection > button {
    max-width: 380px;
    height: 55px;
  }

  .firstSection > button > span {
    font-size: 22px;
  }

  .firstSection > h1 {
    font-size: 32px;
    margin: 10px 0px 0px 0px;
  }

  .firstSection > h2 {
    font-size: 28px;
  }

  .firstSection > p {
    font-size: 18px;
  }

  .secondSection > .mainText {
    font-size: 18px;
    max-width: 1024px;
  }

  .contactContainer > span {
    font-size: 18px;
  }

  .contactContainer > img {
    height: 20px;
    width: 20px;
    margin: 0px 10px 0px 0px;
  }
  .storeSection > p {
    font-size: 18px;
    font-weight: 700;
  }
  .iconContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .divContains {
    margin: 5px;
    width: 20%;
  }
}

@media only screen and (min-width: 1024px) {
  .firstSection {
    grid-column: 1/2;
    justify-content: flex-start;
    padding: 50px 50px 0px;
  }
  .firstSection > h1 {
    font-size: 50px;
  }
  .firstSection > h2 {
    font-size: 40px;
  }
  .firstSection > p {
    font-size: 24px;
  }
  .thirdSection {
    grid-row: 1/3;
  }

  .thirdSection > img {
    max-height: 560px;
  }
  .storeSection > p {
    font-size: 18px;
    font-weight: 700;
  }
  .iconContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 15px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .divContains {
    margin: 10px;
    width: 29%;
  }
}

@media only screen and (min-width: 1440px) {
  .firstSection > button {
    max-width: 420px;
    height: 75px;
  }

  .firstSection > button > span {
    font-size: 25px;
  }

  .firstSection > h1 {
    font-size: 65px;
  }

  .firstSection > h2 {
    font-size: 55px;
  }

  .firstSection > p {
    font-size: 35px;
  }

  .secondSection > .mainText {
    font-size: 25px;
    max-width: 1024px;
  }

  .contactContainer > span {
    font-size: 25px;
  }

  .contactContainer > img {
    height: 25px;
    width: 25px;
    margin: 0px 10px 0px 0px;
  }
  .storeSection > p {
    font-size: 32px;
    font-weight: 700;
  }
  .iconContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 15px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .divContains {
    margin: 10px;
    width: 25%;
  }
}
