.modal__Sign {
  width: 567px !important;
}
.typoGraphy {
  display: flex;
  text-align: center;
  font: normal normal 800 20px/25px Gilroy, sans-serif;
  letter-spacing: 0px;
  color: var(--color-primary-main) !important;
  opacity: 1;
}
.container {
  top: 10%;
  left: 10%;
}
.sigContainer {
  width: 100%;
  margin: 2rem auto;
  background-color: #fff;
  border: 1px solid #d5d5d5;
}
.sigPad {
  width: 100%;
  height: 100%;
}
.buttons_clear {
  width: 184px;
  height: 53px;
  color: var(--color-primary-main);
  background: var(--fondo-blanco) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-primary-main);
  opacity: 1;
}
.buttons_clear:hover {
  color: var(--color-primary-main);
  background: var(--fondo-blanco) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-primary-main);
  opacity: 1;
}

.buttons__Ok {
  width: 163px;
  height: 53px;
  color: var(--fondo-blanco);
  background: var(--color-primary-main) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-primary-main);
  opacity: 1;
}
.buttons__Ok:hover {
  color: var(--fondo-blanco);
  background: var(--color-primary-main) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-primary-main);
}
.buttons__Ok:focus {
  color: var(--fondo-blanco);
  background: var(--color-primary-main) 0% 0% no-repeat padding-box;
  border: 2px solid var(--color-primary-main);
}
.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}
.sign__paint {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .sign__paint {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column-reverse;
  }
  .buttons_clear {
    width: 95%;
    height: 38px;
  }
  .buttons__Ok {
    width: 95%;
    height: 38px;
    margin-bottom: 1rem;
    color: var(--fondo-blanco);
    background: var(--color-primary-main) 0% 0% no-repeat padding-box;
    border: 2px solid var(--color-primary-main);
    opacity: 1;
  }
  .buttons__Ok:hover {
    color: var(--fondo-blanco);
    background: var(--color-primary-main) 0% 0% no-repeat padding-box;
    border: 2px solid var(--color-primary-main);
    opacity: 1;
  }
  .buttons__Ok:focus {
    color: var(--fondo-blanco);
    background: var(--color-primary-main) 0% 0% no-repeat padding-box;
    border: 2px solid var(--color-primary-main);
    opacity: 1;
  }
  .ul_indication {
    font: normal normal normal 16px/23px Arial, sans-serif;
  }
  .typoGraphy_title {
    font: normal normal 800 19px/27px Gilroy, sans-serif;
  }
}
