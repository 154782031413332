.modal_global_style {
    position: relative;
    margin: 0;
    border-width: 1px;
    border-width: 2px;
    border-color: #D1D5DB;
    width: 100%;
    height: 100%;

    @media (min-width: 768px) {
        overflow-y: scroll;
        height: auto;
        width: 80%;
    }

    @media (min-width: 1024px) {
        overflow-y: hidden;
        height: auto !important;
        width: 719px;
        height: 701px;
    }
}