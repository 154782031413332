.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.App-button {
  border: 0;
  display: block;
  width: 100%;
  background: rgb(97, 139, 224);
  color: white;
  font-size: 1.2em;
  padding: 0.5em;
}

.App-viewer {
  position: relative;
  flex: 1;
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

/* For all pages */
/* .all-page-container {
  height: 100%;
  max-height: 500px;
  overflow-x: hidden;
}

.page-controls {
  position: absolute;
  bottom: 20%;
  left: 50%;
  background: var(--fondo-blanco);
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  border-radius: 4px;
} */

/* .page-controls:hover {
  opacity: 1 !important;
} */
@media (min-width: 320px) and (max-width: 600px) {
  .all-page-container {
    height: 100%;
    max-height: 25rem;
    overflow-x: hidden;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
    width: 100% !important;
    height: 100% !important;
  }

  .terms-and-pdf {
    width: 100%;
    height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .all-page-container {
    height: 100% !important;
    max-height: 61vh !important;
    overflow-x: hidden;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
    width: 100% !important;
    height: 100% !important;
  }

  .terms-and-pdf {
    margin-top: 3% !important;
    width: 100%;
    height: 50vh; 
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .all-page-container {
    height: 100%;
    max-height: 37vh;
    overflow-x: hidden;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
    width: 100% !important;
    height: 100% !important;
  }

  .terms-and-pdf {
    width: 100%;
    height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (min-width: 1201px) and (max-width: 1535px) {
  .all-page-container {
    width: 84%;
    height: 100%;
    max-height: 45vh;
    overflow-x: hidden;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
    width: 100% !important;
    height: 100% !important;
  }

  .terms-and-pdf {
    width: 100%;
    height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    /* margin: 24px 11% 0; */
  }
}

@media (min-width: 1536px) {
  .all-page-container {
    height: 100%;
    max-height: 45vh;
    overflow-x: hidden;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
    width: 80% !important;
    height: 100% !important;
  }

  .terms-and-pdf {
    width: 100%;
    height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
