.fail__identity {
  /* border border-2 border-gray-300 */
  border: 2px solid #d1d5db;
  /* This is a rough equivalent to the Tailwind 'border-gray-300' */

  /* relative */
  position: relative;

  /* w-full */
  width: 100%;

  /* h-full */
  height: 100%;

  /* m-0 */
  margin: 0;
}

/* md:w-[80%] md:h-[70%] */
@media (min-width: 768px) {

  /* This assumes the 'md:' breakpoint is at 768px */
  .fail__identity {
    width: 80%;
    height: 70%;
  }
}

/* lg:w-[719px] lg:h-[70%] */
@media (min-width: 1024px) {

  /* This assumes the 'lg:' breakpoint is at 1024px */
  .fail__identity {
    width: 40%;
    /* Fixed width at large screen sizes */
    height: min-content;
  }
}

@media (min-width: 1500px) {

  /* This assumes the 'lg:' breakpoint is at 1024px */
  .fail__identity {
    width: 35%;
    /* Fixed width at large screen sizes */
    height: min-content;
  }
}
@media (max-width: 600) {

  /* This assumes the 'lg:' breakpoint is at 1024px */
  .fail__identity {
    height: 80%;
  }
}


.identity__confirmed {
  /* border border-2 border-gray-300 */
  border: 2px solid #d1d5db;
  /* This is a rough equivalent to the Tailwind 'border-gray-300' */

  /* relative */
  position: relative;

  /* w-full */
  width: 100%;

  /* h-full */
  height: 100%;

  /* m-0 */
  margin: 0;
}

/* md:w-[80%] md:h-[70%] */
@media (min-width: 768px) {

  /* This assumes the 'md:' breakpoint is at 768px */
  .identity__confirmed {
    width: 80%;
    height: 70%;
  }
}

/* lg:w-[719px] lg:h-[70%] */
@media (min-width: 1024px) {

  /* This assumes the 'lg:' breakpoint is at 1024px */
  .identity__confirmed {
    width: 719px;
    /* Fixed width at large screen sizes */
    height: min-content;
  }
}

@media (max-width: 600) {

  /* This assumes the 'lg:' breakpoint is at 1024px */
  .identity__confirmed {
    height: 80%;
  }
}