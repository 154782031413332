.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 100px;
}

.not-found h1 {
    font-size: 100px;
    margin: 0;
    color: #53a600;
    font-family: 'Red Hat Text';
    /* Rojo */
}

.not-found h2 {
    margin-top: 0;
    color: #333;
    font-family: 'Red Hat Text';
}

.not-found p {
    color: #555;
    font-family: 'Red Hat Text';
}

.not-found a {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: rgba(0, 54, 10, 1);
    /* Azul */
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.not-found a:hover {
    background-color: rgba(0, 54, 10, 1);
    /* Azul oscuro */
}