.floating-button {
  position: absolute;
  padding: 10px 20px;
  background-color: #25d366 !important;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: move;
  z-index: 1;
  user-select: none;
  /* Evita que el texto sea seleccionado al mover el botón */
  /* Asegurar que el botón se mantenga dentro de los límites de la pantalla */
  left: 10px;
  top: 10px;
  right: auto;
  bottom: auto;
}

.whatsapp {
  position: fixed !important;
  width: 35px;
  height: 35px;
  bottom: 15px;
  right: 15px;
  background-color: #25d366 !important;
  color: #FFF !important;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 1000;
  touch-action: none; /* Desactiva el manejo predeterminado de gestos táctiles */
  display: flex;
  justify-content: center;
  align-items: center;
}

.whatsapp-icon {
  margin-top: 13px;
}

@media (min-width: 600px) { 
  .whatsapp {
    width: 60px;
    height: 60px;
  }
}
