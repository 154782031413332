.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 50%;
    height: 50%;
    border-color: 1px red;
    background-color: aqua;
}

@media (min-width: 768px) {
    .modal {
        margin-top: 15px;
    }
}