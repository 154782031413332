.cardBlack1 {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 286.33px;
    height: 160px;
  }
  .vectorIcon {
    position: absolute;
    height: 100%;
    width: 49.34%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 50.66%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .descargaElApp1 {
    align-self: stretch;
    position: relative;
    line-height: 18px;
    font-weight: 500;
  }
  .salaYDisfruta1 {
    align-self: stretch;
    position: relative;
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 16px;
    font-weight: 500;
    color: rgba(16, 24, 40, 0.64);
  }
  .topText {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 117px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .webAppCardMarketingChild {
    position: absolute;
    top: 0px;
    left: calc(50% - 26.26px);
    object-fit: cover;
  }
  @media (max-width: 600px) {
    .webAppCardMarketingChild {
      position: absolute;
      top: 0px;
      left: calc(50% - 26.26px);
      object-fit: cover;
    }
    .vectorIcon {
      position: absolute;
      height: 100%;
      width: 49.34%;
      top: 0%;
      right: 0%;
      bottom: 0%;
      left: 55.66%;
      max-width: 100%;
      overflow: hidden;
      max-height: 100%;
    }
 
  }
  .webAppCardMarketing {
    flex: 1;
    width: 282px;
    position: relative;
    border-radius: 20px;
    border: 0.7px solid #eaecf0;
    box-sizing: border-box;
    height: 160px;
    overflow: hidden;
    flex-shrink: 0;
    text-align: left;
    font-size: 16px;
    color: #026e18;
    font-family: "Red Hat Text", sans-serif;
  }
  