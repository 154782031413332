@import "./var.scss";

.btnFirmarPdf {
  background: var(--color-primary-main);
  border: 1px solid var(--color-primary-main);
  color: var(--fondo-blanco);
  text-transform: uppercase;
  border-radius: 0px;
}

.contentPdfContract {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 80%;
  margin: auto;
}

.div__Terms__Contract {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: end;
  width: 80%;
  margin: 0vh auto;
}

.main__verification {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0 !important;
}

.textCedula {
  margin-top: 3%;
  color: var(--color-primary);
}

.main_container_instruc {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.btnHolder {
  display: flex;
  justify-content: flex-end;
}

.btnNextStep {
  background: var(--color-primary-main);
  border: 1px solid var(--color-primary-main);
  color: var(--fondo-blanco);
  text-transform: uppercase;
  border-radius: 0px;
}

.btnNextStep:hover {
  background: var(--color-primary-main);
  border: 1px solid var(--color-primary-main);
  color: var(--fondo-blanco);
}

.btnNextStep:focus {
  background: var(--color-primary-main);
  border: 1px solid var(--color-primary-main);
  color: var(--fondo-blanco);
}

.h1Instruccion {
  font: normal normal 900 1.5rem/25px Gilroy, sans-serif;
  text-align: center;
  margin: 2em auto 2rem auto;

  /* Arriba | Derecha | Abajo | Izquierda */
}

.listInstructions {
  width: 80%;
}

.btnSi {
  background: var(--fondo-blanco);
  border: 1px solid var(--color-primary-main);
  color: var(--color-primary-main);
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 0px;
}

.btnSi:hover {
  background: var(--fondo-blanco);
  border: 1px solid var(--color-primary-main);
  color: var(--color-primary-main);
}

.btnNo {
  background: var(--color-primary-main);
  border: 1px solid var(--color-primary-main);
  color: var(--fondo-blanco);
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 0px;
}

.btnNo:hover {
  background: var(--color-primary-main);
  border: 1px solid var(--color-primary-main);
  color: var(--fondo-blanco);
}

.btnVerify {
  display: flex;
  align-items: center;
}

.btnBack {
  color: var(--color-primary-main);
  border: 1px solid var(--color-primary-main);
  background: var(--fondo-blanco);
  border-radius: 0px;
  text-transform: uppercase;
}

.btnBack:hover {
  color: var(--color-primary-main);
  border: 1px solid var(--color-primary-main);
  background: var(--fondo-blanco);
}

.divApc {
  justify-content: space-between;
  display: flex;
  margin: 5em 5%;
  width: 90%;
}

.div__terms_PDF {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  margin: 2vh auto;
}

.divFooter {
  display: flex;
  justify-content: center;
}

.divFooterCalculatorPhone {
  display: flex;
  justify-content: center;
}

.politicalForm {
  padding: 0% 5%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.spaceRow {
  margin-top: 4%;
}

.cartaExclu {
  text-align: left;
  font: normal normal normal 12px/19px Arial, sans-serif;
  letter-spacing: 0px;
  color: var(--fondo-negro);
  opacity: 1;
  margin: 3rem 0;
}

@media (min-width: 901px) and (max-width: 1200px) {
  .cartaExclu {
    text-align: left;
    font: normal normal normal 12px/19px Arial, sans-serif;
    letter-spacing: 0px;
    color: var(--fondo-negro);
    opacity: 1;
    margin: 1rem 0;
  }
}

@media (max-height: 768px) {
  .contentPdfContract {
    display: block !important;
    /* align-items: center; */
    /* flex-direction: column; */
    /* overflow-y: scroll; */
    /* width: 80%; */
    /* max-height: 41rem; */
    /* height: 45rem; */
    /* margin: auto; */
  }
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .contentPdfContract {
    display: block !important;
    /* align-items: center; */
    /* flex-direction: column; */
    /* overflow-y: scroll; */
    /* width: 80%; */
    /* max-height: 41rem; */
    /* height: 45rem; */
    /* margin: auto; */
  }

  .classTabStyle {
    height: 20rem !important;
  }
}

@media (max-height: 568px) {
  .main_container_instruc {
    margin: 4rem auto !important;
    position: static;
    height: 100%;
  }
}

@media (max-width: 600px) {
  .cartaExclu {
    text-align: left;
    font: normal normal normal 12px/19px Arial, sans-serif;
    letter-spacing: 0px;
    color: var(--fondo-negro);
    opacity: 1;
    margin: 4rem 0;
  }

  .select {
    height: 3.5rem;

    /* Vertical | Horizontal */
    margin: 12px auto;
  }

  .divFooter {
    width: 87%;
    margin: 14% auto;
    flex-direction: column-reverse;
  }

  .divFooterCalculatorPhone {
    width: 87%;
    margin: 14% auto;
    flex-direction: column-reverse;
  }

  .div__terms_PDF {
    font: normal normal normal 1.1rem/21px Gilroy, sans-serif;
    color: var(--color-primary-main);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;
    text-align: center;
  }

  .divApc {
    margin: 4% auto 14% auto;
    flex-direction: column-reverse;
    width: 90%;
  }

  .Pdf_className {
    font: normal normal 900 1.1rem/25px Gilroy, sans-serif;
    text-align: center;
    margin: 1em 1rem 0em 1rem;

    /* Arriba | Derecha | Abajo | Izquierda */
  }

  .divContractPdf {
    font: normal normal normal 1.1rem/21px Gilroy, sans-serif;
    color: var(--color-primary-main);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 11% auto 11% auto;
    width: 100%;
    text-align: center;
    flex-direction: column;
  }

  .content_button_Pdf {
    font: normal normal normal 1.1rem/21px Gilroy, sans-serif;
    color: var(--color-primary-main);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 7% auto 11% auto;
    width: 100%;
    text-align: center;
    flex-direction: column-reverse;
  }

  .btnFirmarPdf {
    width: 95%;
    height: 40px;
    margin: auto;
  }

  .contentPdfContract {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    width: 80%;
    margin: auto;
  }

  .div__Terms__Contract {
    font: normal normal normal 1.1rem/21px Gilroy, sans-serif;
    color: var(--color-primary-main);
    display: flex;
    justify-content: initial;
    align-items: flex-start;
    margin: auto;
    width: 100%;
  }

  /* estilos de scandId */

  .navli {
    text-align: left;
    font: normal normal normal 1rem/21px Arial, sans-serif;
    margin: 5% 1% auto 2%;

    /* Arriba | Derecha | Abajo | Izquierda */
  }

  .main__verification {
    text-align: center;
    margin: 5em 1em;
  }

  .textCedula {
    font: normal normal normal 1rem/22px Arial, sans-serif;
  }

  .indication_div {
    font: normal normal normal 1rem/21px Arial, sans-serif;
    color: var(--color-primary);
    margin-top: 10%;
  }

  .main_container_instruc {
    margin: auto;
    height: 100%;
  }

  .btnHolder {
    margin-bottom: 4em !important;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
  }

  .btnNextStep {
    width: 95%;
    height: 40px;
    margin: auto;
  }

  .h1Instruccion {
    font: normal normal 900 1.5rem/25px Gilroy, sans-serif;
    text-align: center;
    margin: 2em auto 2rem auto;
    padding: 0px 8px;
    /* Arriba | Derecha | Abajo | Izquierda */
  }

  .select {
    height: 3.5em;

    /* Vertical | Horizontal */
    margin: 12px auto;
  }

  .input {
    height: 3rem;

    /* Vertical | Horizontal */
    margin: 12px auto;
  }

  .inputStyle {
    height: 3.5em;
    width: 100%;
    /* Vertical | Horizontal */
    margin: 12px auto;
  }

  .btnSi {
    width: 90%;
    margin: auto;
    margin-top: 5px;
  }

  .btnNo {
    width: 90%;
    margin: auto;
  }

  .btnVerify {
    flex-direction: column-reverse;
  }

  .btnBack {
    width: 95%;
    height: 40px;
    margin: auto;
    margin-top: 5%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .divFooter {
    justify-content: space-between;
    display: flex;
    margin: 5em 5%;
    width: 90%;
  }

  .divFooterCalculatorPhone {
    justify-content: space-between;
    display: flex;
    margin: 3em 5%;
    width: 90%;
  }

  .btnFirmarPdf {
    width: 120px;
    height: 50px;
    font-size: 1rem;
  }

  .Pdf_className {
    font: normal normal 900 1.5rem/25px Gilroy, sans-serif;
    text-align: center;
    margin: 2em auto 2rem auto;

    /* Arriba | Derecha | Abajo | Izquierda */
  }

  .divContractPdf {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin: 0 5%;
    margin-bottom: 5em;
  }

  .content_button_Pdf {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 5%;
    margin-bottom: 5em;
  }

  .contentPdfContract {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    width: 80%;
    margin: auto;
  }

  .textCedula {
    font: normal normal normal 1.3rem/19px Arial, sans-serif;
  }

  .indication_div {
    font: normal normal normal 1.3rem/30px Arial, sans-serif;
    color: var(--color-primary);
    margin-top: 4%;
  }

  .btnHolder {
    justify-content: space-between;
    margin: 0 5% 5em;
  }

  .btnNextStep {
    width: 120px;
    height: 50px;
    font-size: 1rem;
  }

  .h1Instruccion {
    font: normal normal 900 1.5rem/25px Gilroy, sans-serif;
    text-align: center;
    margin: 2em auto 2rem auto;

    /* Arriba | Derecha | Abajo | Izquierda */
  }

  .main__verification {
    text-align: center;
    margin: auto;
  }

  .navli {
    text-align: left;
    font-size: 1.1rem;
    margin-top: 1em;
  }

  .input {
    height: 4em;

    /* Vertical | Horizontal */
    margin: 13px auto;
  }

  .btnSi,
  .btnNo {
    width: 90%;
    margin: auto;
    margin-top: 5px;
  }

  .btnVerify {
    flex-direction: column-reverse;
  }

  .btnBack {
    width: 120px;
    height: 50px;
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .politicalForm {
    overflow-y: inherit;
    overflow-x: inherit;
  }
}

@media (max-width: 820px) {
  .politicalForm {
    padding: 0 5%;
    height: 100%;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .politicalForm {
    padding: 0 5%;
    height: 100%;
  }

  .divFooter {
    justify-content: space-between;
    display: flex;
    margin: 6em 5%;
    width: 90%;
  }

  .divFooterCalculatorPhone {
    justify-content: space-between;
    display: flex;
    margin: 3em 5%;
    width: 90%;
  }

  .btnFirmarPdf {
    width: 25%;
    height: 50px;
    font-size: 0.9rem;
  }

  .Pdf_className {
    font: normal normal 900 2rem/43px Gilroy, sans-serif;
    width: 80%;
    margin: 5vh auto 2vh auto;
  }

  .divContractPdf {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin: 0 5%;
    margin-bottom: 5em;
  }

  .content_button_Pdf {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 5%;
    margin-bottom: 5em;
  }

  .textCedula {
    font: normal normal normal 1.1rem/19px Arial, sans-serif;
  }

  .indication_div {
    font: normal normal normal 1.3rem/25px Arial, sans-serif;
    color: var(--color-primary);
    margin-top: 4%;
  }

  .btnHolder {
    justify-content: space-between;
    margin-top: 0;
    margin-right: 5%;
    margin-bottom: 6em !important;
    /* Using !important to ensure this rule has precedence */
    margin-left: 5%;
  }

  .btnNextStep {
    width: 120px;
    height: 50px;
    font-size: 1rem;
  }

  .h1Instruccion {
    font: normal normal 900 2rem/43px Gilroy, sans-serif;
    text-align: center;
    margin: 2em auto 2rem auto;

    /* Arriba | Derecha | Abajo | Izquierda */
  }

  .input {
    height: 3.5rem;

    /* Vertical | Horizontal */
    margin: 16px auto;
  }

  .main__verification {
    text-align: center;
    margin: auto;
  }

  .btnSi,
  .btnNo {
    font-size: 1rem;
    width: 150px;
  }

  .btnVerify {
    justify-content: space-evenly;
    align-items: center;
  }

  .btnBack {
    width: 120px;
    font-size: 1rem;
  }

  .navli {
    text-align: left;
    font-size: 1.2rem;
    margin-top: 1em;
  }
}

@media (min-width: 1201px) and (max-width: 1535px) {
  .politicalForm {
    margin: 0 0 auto 0;
  }

  .divFooter {
    justify-content: space-between;
    display: flex;
    margin: 6em 5%;
    width: 90%;
  }

  .divFooterCalculatorPhone {
    justify-content: space-between;
    display: flex;
    margin: 1em 5%;
    width: 90%;
  }

  .btnFirmarPdf {
    width: 20%;
    height: 6vh;
    font-size: normal normal normal 16px/20px Arial, sans-serif;
  }

  .Pdf_className {
    font: normal normal 900 1.9rem/40px Gilroy, sans-serif;
    width: 80%;
    margin: 6vh auto 2vh auto;
  }

  .content_button_Pdf {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 5%;
    margin-bottom: 5em;
  }

  .divContractPdf {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin: 5% 5%;
    margin-bottom: 5em;
  }

  .navli {
    text-align: left;
    font-size: 1.2rem;
    margin-top: 1em;
  }

  .main__verification {
    text-align: center;
    margin: auto;
  }

  .textCedula {
    font: normal normal normal 1.1rem/19px Arial, sans-serif;
  }

  .indication_div {
    font: normal normal normal 1.5rem/30px Arial, sans-serif;
    color: var(--color-primary);
    margin-top: 3%;
  }

  .btnHolder {
    justify-content: space-between;
    margin-top: 0;
    margin-right: 5%;
    margin-bottom: 6em !important;
    /* Using !important to ensure this rule has precedence */
    margin-left: 5%;
  }

  .btnNextStep {
    width: 15%;
    height: 6vh;
    font: normal normal normal 18px/20px Arial, sans-serif;
  }

  .h1Instruccion {
    font: normal normal 900 2rem/53px Gilroy, sans-serif;
    text-align: left;
    width: 80%;
    margin: 4em auto 2rem auto;
  }

  .input {
    height: 3.5rem;

    /* Vertical | Horizontal */
    margin: 16px auto;
  }

  .btnSi,
  .btnNo {
    width: 150px;
  }

  .btnVerify {
    justify-content: space-evenly;
    align-items: center;
  }

  .btnBack {
    width: 15%;
    height: 6vh;
    font: normal normal normal 16px/20px Arial, sans-serif;
  }
}

@media (min-width: 1536px) {
  .politicalForm {
    margin: 0 0 auto 0;
  }

  .divFooter {
    justify-content: space-between;
    display: flex;
    margin: 6em 5%;
    width: 90%;
  }

  .divFooterCalculatorPhone {
    justify-content: space-between;
    display: flex;
    margin: 1em 5%;
    width: 90%;
  }

  .divContractPdf {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin: 0 5%;
    margin-bottom: 5em;
  }

  .content_button_Pdf {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 5em;
  }

  .btnFirmarPdf {
    width: 15%;
    height: 6vh;
    font-size: normal normal normal 16px/20px Arial, sans-serif;
  }

  .Pdf_className {
    font: normal normal 900 2.2rem/45px Gilroy, sans-serif;
    width: 80%;
    margin: 6vh auto 2vh auto;
  }

  .navli {
    text-align: left;
    font-size: 1.7rem;
    margin-top: 1em;
  }

  .main__verification {
    text-align: center;
    margin: auto;
  }

  .textCedula {
    font: normal normal normal 1.3rem/22px Arial, sans-serif;
  }

  .indication_div {
    font: normal normal normal 1.7rem/35px Arial, sans-serif;
    color: var(--color-primary);
    margin-top: 3%;
  }

  .btnHolder {
    justify-content: space-between;
    margin-top: 0;
    margin-right: 5%;
    margin-bottom: 6em !important;
    /* Using !important to ensure this rule has precedence */
    margin-left: 5%;
  }

  .btnNextStep {
    width: 15%;
    height: 6vh;
    font: normal normal normal 18px/22px Arial, sans-serif;
  }

  .h1Instruccion {
    font: normal normal 900 2.8rem/63px Gilroy, sans-serif;
    width: 80%;
  }

  .input {
    height: 3.5rem;

    /* Vertical | Horizontal */
    margin: 16px auto;
  }

  .btnSi,
  .btnNo {
    width: 150px;
  }

  .btnVerify {
    justify-content: space-evenly;
    align-items: center;
  }

  .btnBack {
    width: 15%;
    height: 6vh;
    font: normal normal normal 18px/22px Arial, sans-serif;
  }
}

.carta_Modal {
  width: 486px;
  height: 384px;
  border-radius: 10px;
  opacity: 1;
}

.typoGraphy_title {
  margin: auto;
  width: 20rem;
  display: flex;
  text-align: center;
  font: normal normal 800 20px/27px Gilroy, sans-serif;
  letter-spacing: 0px;
  color: var(--color-primary-main) !important;
  opacity: 1;
}

.ModalIndi {
  width: 486px;
  height: 384px;
}

.ul_indication {
  display: flex;
  margin: 2rem auto 2rem auto;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font: normal normal normal 20px/23px Arial, sans-serif;
  letter-spacing: 0px;
  color: #13a438;
  opacity: 1;
}

.button_next_sign {
  display: flex !important;
  color: #ffffff !important;
  align-items: center !important;
  margin: 0 auto 1rem auto !important;
  background: #13a438 !important;
  border: 2px solid #13a438 !important;
  height: 53px !important;
}

.button_next_sign:hover {
  display: flex !important;
  color: #ffffff !important;
  align-items: center !important;
  margin: 0 auto 1rem auto !important;
  background: #13a438 !important;
  border: 2px solid #13a438 !important;
  height: 53px !important;
}

.icon_success {
  display: flex;
  align-items: center;
  margin: 2rem auto 0.5rem auto;
  width: 46px;
  height: 46px;
}
