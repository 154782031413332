.mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}

.termsContainer {
    font-size: 12px;
    padding: 1rem 20px 1rem 20px;
    color: #545454;
    line-height: 1rem;
    width: 100%;
    max-width: 1366px;
    align-self: center;
}

.buttonChubb {
    display: flex;
    width: 100%;
    max-width: 1366px;
    flex-direction: column;
    border-color: #bbf7d0;
    align-self: center;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
}

.buttonChubb>* {
    margin: 5px 0px;
    border-radius: 100px;
    font-size: 18px;
    line-height: 1.75rem;
    width: 100%;
    cursor: pointer;
    padding: 8px 0px;
}

.buttonChubb>*:nth-child(1) {
    color: white;
}

.buttonChubb>*:nth-child(2) {
    color: #026E18;
    border-color: #026E18;
    border-width: 1px;
}

@media only screen and (min-width: 768px) {
    .buttonChubb {
        flex-direction: row-reverse;
        justify-content: flex-start;
    }

    .buttonChubb>* {
        width: 18rem;
        margin: 5px 5px;
        padding: 10px 0px;
    }
}

.mobileTextContainer {
    text-align: center;
    padding: 0px 20px;
    margin-top: 1rem;
}

.mobileTextContainer>*:nth-child(1) {
    font-size: 14px;
    line-height: 1.25rem;
    font-weight: 500;
}

.mobileTextContainer>*:nth-child(2) {
    color: #545454;
    margin-left: 0.25rem;
    font-size: 14px;
}

.desktopContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #F2F4F7;
    margin-top: 1.25rem;
    padding: 5px 0px;
}

.desktopSubContainer {
    display: flex;
    width: 100%;
    max-width: 1820px;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
}

.desktopContainer__text {
    display: none;
    font-size: 14px;
    line-height: 1rem;
}

.desktopContainer__text>*:nth-child(1) {
    font-weight: 500;
}

.desktopContainer__text>*:nth-child(1) {
    color: "#9ca3af5";
}



.desktopContainer__price {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    color: #011E41;
    font-weight: 500;
}

.desktopContainer__price>*:nth-child(1) {
    color: #545454;
    font-size: 18px;
    line-height: 1.35rem;
}

.desktopContainer__price>*:nth-child(2) {
    margin-top: 0.125rem;
    font-size: 25px;
    line-height: 1.35rem;
}

@media only screen and (min-width: 768px) {
    .mobileTextContainer {
        display: none;
    }

    .desktopContainer__text {
        display: block;
        max-width: 50%;
    }

    .desktopContainer__price>*:nth-child(1) {
        margin-right: 2rem;
        font-size: 18px;
    }

    .desktopContainer__price>*:nth-child(2) {
        margin-top: 0px;
        font-size: 18px;
    }

    .desktopContainer__price {
        display: flex;
        flex-direction: row;
        width: auto;
        align-items: flex-end;
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}

@media only screen and (min-width: 1440px) {
    .desktopContainer__text {
        font-size: 16px;
    }

    .desktopContainer__price>*:nth-child(1) {
        font-size: 30px;
    }

    .desktopContainer__price>*:nth-child(2) {
        font-size: 36px;
    }

    .desktopContainer {
        padding: 20px 0px;
    }
}

/* #011E41 */

.detailsInsurance {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    max-width: 1820px;
    padding: 0px 10px;
    align-self: center;
}

.detailsInsurance>* {
    width: calc(100% - 10px);
    margin: 5px 5px;
    display: flex;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    width: 60px;
    height: 60px;
    padding: 0px 10px;
    margin: 0px 0px 20px 0px;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.textContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.textContainer>*:nth-child(1) {
    color: #011E41;
    font-weight: 500;
    font-size: 16px;
    margin: 0px 0px;
}

.textContainer>*:nth-child(2) {
    font-size: 14px;
    color: #545454;
}

@media only screen and (min-width: 768px) {
    .detailsInsurance>* {
        width: calc(50% - 10px);
        border-width: 2px;
        border-color: #D0D5DD;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px 12px;
        border-radius: 5px;
        aspect-ratio: 2/1.2;
    }

    .imageContainer{
        width: 100%;
    }

    .textContainer>*:nth-child(1) {
        font-size: 18px;
        margin: 0px 0px 10px 0px;
    }

    .textContainer>*:nth-child(2) {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1024px) {
    .detailsInsurance>* {
        width: calc(25% - 10px);
        aspect-ratio: 2/1.5;
    }
}

@media only screen and (min-width: 1440px) {
    .textContainer>*:nth-child(1) {
        color: #011E41;
        font-weight: 500;
        font-size: 24px;
        margin: 0px 0px;
    }


    .imageContainer {
        height: 85px;
    }

    .detailsInsurance>* {
        padding: 40px 12px;
    }
}
/* Headers */
.mainheader {
    display: flex;
    flex-direction: column;
}

.bannerImage {
    display: none;
    width: 90%;
    max-height: 152px;
    align-self: center;
    margin: 20px;
    border-radius: 4px;
    object-fit: contain;
}

.chubbImage {
    object-fit: contain;
    height: 14px;
    width: 100%;
    margin: 10px 0px 10px 0px;
}

.text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #011E41;
    text-align: center;

    padding: 0px 5px;
    font-size: 25px;
    line-height: 1.5rem;
}

.separator {
    width: 80%;
    max-width: 768px;
    height: 1px;
    background-color: #011E41;
    align-self: center;
    margin: 10px 0px;
}

@media only screen and (min-width: 768px) {
    .text {
        font-size: 1.55rem;
        line-height: 2.25rem;
    }

    .chubbImage {
        height: 17px;
    }

    .bannerImage {
        display: flex;
    }
}

@media only screen and (min-width: 1024px) {
    .text {
        font-size: 30px;
        line-height: 2.25rem;
    }
}

@media only screen and (min-width: 1440px) {
    .text {
        font-size: 43px;
        line-height: 2.25rem;
    }
}