.scan-area {
  height: 100%;
  width: 100%;
  background-color: black;
  text-align: center;
  padding-top: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* .eid-loading-img {
    max-height: 250px;
  } */
}
.eid-loading-img {
  max-height: 250px;
}

/* ELECTRONIC ID STYLES OVERWRITE */
#videoid-wrapper #videoid-mark-roi {
  border-color: var(--color-primary-main) !important;
  border-style: solid !important;
}
#videoid-wrapper .videoid-notification.notification-text.show {
  background: var(--color-primary-main) !important;
}
#videoid-wrapper .paragraph-text.paragraph {
  font-weight: normal !important;
}
#videoid-wrapper .videoid-notification.notification-text.show.warning {
  background: #c7351a !important;
}
#videoid-wrapper .title.title-image {
  color: var(--color-primary-main) !important;
}
#videoid-wrapper .a.a-image {
  border-color: var(--color-primary-main) !important;
  color: var(--color-primary-main) !important;
}
#videoid-wrapper .title.title-video {
  color: var(--color-primary-main) !important;
}
#videoid-wrapper .a.a-video {
  color: var(--color-primary-main) !important;
  border-color: var(--color-primary-main) !important;
}
#videoid-wrapper .spinner-rotate {
  border-top-color: var(--color-primary-main) !important;
}
#videoid-wrapper .svg-face {
  stroke: var(--color-primary-main) !important;
}
#videoid-wrapper #video-error {
  align-items: center !important;
}
#videoid-wrapper #repeat {
  border-color: #c7351a !important;
  color: #c7351a !important;
}
#videoid-wrapper .title-error {
  color: #c7351a !important;
}
#logo-eid {
  display: none !important;
}
/*  */
.eid-video-player {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: auto !important;
}

.eid-overlay.eid-on-top{
  /* top:10%; */
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: auto;
  position: fixed;
}