.ant-radio-checked .ant-radio-inner {
  border-color: #c8c5c5 !important;

  &:after {
    background-color: var(--color-primary-main);
  }
}

.ant-radio:hover .ant-radio-inner {
  border-color: var(--color-primary-main);
}

.ant-checkbox-inner {
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 0px;
  background-color: var(--fondo-blanco);
  border: 1px solid var(--fondo-blanco) !important;
  border-color: #c8c5c5 !important;

  &::after {
    color: var(--fondo-blanco);
    text-align: center;
    width: 5px;
    height: 10px;
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    font-size: 10px;
    width: 20px;
    height: 20px;
    border-radius: 0px;
    background-color: var(--fondo-blanco);
    border: 1px solid var(--color-primary-main) !important;

    &::after {
      border: 2px solid var(--color-primary-main);
      border-top: 0;
      border-left: 0;
    }
  }

  &::after {
    border: 1px solid var(--color-primary-main);
  }
}

.ant-input {
  border: 1px solid #b7b6b6;
  opacity: 1;

  &:hover {
    opacity: 1;
  }
}
