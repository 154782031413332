@import "./input-overwrite.scss";

*,
body,
html {
  margin: 0px;
  padding: 0px;
}

body {
  background-color: var(--fondo-blanco);
}

.verifyButtonArea {
  display: flex;
  justify-content: center;
}

.left-side {
  display: block;
  width: 100%;
}

.ant-modal-content {
  border-radius: 10px;
  box-shadow: 4px 3px 7px #d6d6d6;
}

.panel-fixed {
  width: 100%;
  height: 60px;
  text-align: center;
}

.bg-green {
  background: var(--color-primary-main) 0% 0% no-repeat padding-box;
}

.bg-green-light {
  background: var(--color-primary-main) 0% 0% no-repeat padding-box;

  .logo {
    display: inline-block;
  }
}

.logo-background {
  display: none;
}

.titles-bold {
  font-size: 40px;
  font-family: Gilroy, sans-serif;
  font-weight: bold;
  margin-bottom: 0px;
  color: #309c54;
}

.right-side {
  background-color: var(--fondo-blanco);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container-logo {
  display: flex;
  justify-content: center;
}

.mia-logo {
  width: auto !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

.text-centered {
  text-align: center;
  margin: 10px;
  margin-top: 20%;
}

.text-centered-Welcome {
  text-align: center;
  margin: -14px 10px 7px 10px !important;
}


.text-centered-instruccion {
  text-align: left;
  width: 90%;
  margin: 10% 0 0 5%;
}

.title-istructions {
  font-family: Gilroy, sans-serif;
  font-size: 3.7em;
  line-height: 1.2em;
  margin-bottom: 15px;
}

.content-cr {
  margin: 0;
  padding: 0;
  background: var(--fondo-blanco);
  background-size: cover;
  height: 100vh;
}

.contentCardRequest * {
  font-family: Arial, Helvetica, sans-serif;
}

.container-body {
  width: 100%;
}

.container-footer {
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 10px;
  margin-bottom: 20px;
}

.validation-container h1 {
  text-align: center;
  font: normal normal 800 20px/25px Gilroy, sans-serif;
  letter-spacing: 0px;
  color: var(--color-primary-main);
  opacity: 1;
}

.externalResidents {
  height: 6vh;
}

.identity-logo {
  width: 80px;
}

.container-button-selector {
  margin: 30px;
}

.label-muted {
  margin-right: 10px;
  color: #b7b6b6;
}

.text-dark {
  font-size: 12px;
  color: #545454;
}

.title-align-left {
  text-align: left;
  padding: 0px 10px;
}

.terms-and-conditions {
  padding: 20px;

  .term {
    font-size: 12px;
    margin-bottom: 20px;
  }
}

.smallIcon {
  height: 25px;
  margin: 5px;
}

.container-body {
  .onboarding-form,
  .onboarding-form-full {
    padding: 20px 25px;
  }
}

/* modal validations */

.validation-container {
  text-align: center;

  h1 {
    font-size: 16px;
    font-family: Gilroy, sans-serif;
    margin-bottom: 0px;
    color: #309c54;
  }

  .ant-form-item {
    margin-bottom: 10px !important;
  }

  input {
    height: 40px;
    width: 80%;
  }

  .description {
    color: #309c54;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.__holder {
  position: fixed;
  z-index: 15000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.compress-container {
  margin: 0 10vw;
}

.invalid__Identity {
  box-shadow: 4px 3px 7px #d6d6d6;
  border-radius: 10px;
  opacity: 1;
}

.camera__permisos {
  width: 620px;
  height: 429px;
  box-shadow: 4px 3px 7px #d6d6d6;
  border-radius: 10px;
  opacity: 1;
}

.icon__info {
  background: transparent url("../../../assets/images/info-icon.webp") 0% 0% no-repeat padding-box;
  width: 46px;
  height: 46px;
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}

.icon__camera {
  width: 46px;
  height: 46px;
  margin-left: auto;
  margin-right: auto;
}

.info-help {
  text-align: center;
  letter-spacing: 0px;
  color: var(--color-primary-main);
  opacity: 1;
}

.reject_text {
  text-align: center;
  font: normal normal 800 20px/23px Gilroy, sans-serif;
  color: var(--color-primary-main);
}

.reject_text_second {
  text-align: center;
  font: normal normal normal 20px/23px Arial, sans-serif;
  letter-spacing: 0px;
  color: var(--color-primary-main);
  opacity: 1;
}

.padre {
  display: flex;
  justify-content: space-between !important;
  padding: 8px 95px !important;
}

.hijo {
  padding: 10px;
  margin: 10px;
  justify-content: space-between;
}

/* li.navli {
	 text-align: left;
	 margin-left: 5%;
	 font-size: 22px;
	 padding-bottom: 35px;
} */

li::marker {
  color: var(--color-primary-main);

  /* Coloreamos la viñeta */
}

.instruccion__style {
  font-size: 16px;
  text-align: justify;
  color: #959595;
  font-family: Arial, Helvetica, sans-serif;
}

.padre {
  display: flex;
  justify-content: center;
}

.hijo {
  padding: 10px;
  margin: 10px;
}

.description__modal {
  color: #309c54;
  font: italic normal normal 14px/20px Arial, sans-serif;
}

.text-info {
  color: var(--color-primary-main);
  font-size: 22px !important;
  font-style: oblique;
}

.ant-checkbox-inner {
  border-color: var(--color-primary-main) !important;
  border: 3px solid var(--color-primary-main) !important;
  border-radius: 4px;
  opacity: 1;
}

.ant-checkbox-checked .ant-checkbox-inner {
  font-size: 10px;
  width: 20px;
  height: 20px;
  background-color: var(--fondo-blanco);
  border: 3px solid var(--color-primary-main) !important;
  border-radius: 4px;
  opacity: 1;
}

.ant-layout.ant-layout-has-sider {
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100vh;
}

.ant-layout-sider-zero-width-trigger {
  display: none;
}

.ant-layout,
.ant-layout-footer {
  background: var(--fondo-blanco);
}

@media (min-width: 1025px) {
  .finish__screen {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footerCongrats1 {
    display: none;
  }

  .consulta__p {
    width: 92%;
  }

  .congrats__button {
    background: var(--color-primary-main);
    border: 1px solid var(--fondo-blanco);
    color: var(--fondo-blanco);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    width: 156px;
    float: right;
    height: 53px;
  }

  .text-centered-Congrats {
    h2 {
      font-size: 3em;
      font-weight: 500;
      font-family: Gilroy, sans-serif;
      color: var(--color-primary-main);
      text-align: center;
    }

    p {
      text-align: center;
      padding-left: 5%;
      padding-right: 5%;
      justify-content: center !important;
      color: #545454;
      font-size: 25px;
      margin-bottom: 10px;
    }

    .congrats__icon {
      text-align: center;

      span {
        color: #545454;
        font-size: 1.9em;
        margin-right: 100px;
      }
    }
  }

  .Congrats_footer {
    float: right;
  }

  .ant-layout-sider-children .logo {
    text-align: center !important;
    width: 90% !important;
    height: auto;
    margin-top: 60vh;
  }

  .ConoceMas__class {
    margin: 4%;
    margin-top: 30px;

    .Button__ConoceMas {
      width: 18vw;
      background: var(--color-primary-main);
      border: 1px solid var(--fondo-blanco);
      color: var(--fondo-blanco);
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 0px;
      margin: 2px 0em 2% 0em !important;
      margin: 24px;
    }
  }

  .salay__colum .label-muted {
    visibility: hidden;
  }

  .input__salary {
    padding: 2% 0px 2px 0px;
  }

  .onboardingButtonVID {
    background: var(--fondo-blanco);
    border: 2px solid var(--color-primary-main);
    color: var(--color-primary-main);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    width: 120px;

    &:hover {
      background: var(--color-primary-main);
      border: 2px solid var(--fondo-blanco);
      color: var(--fondo-blanco);
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 0px;
      width: 120px;
    }
  }

  .onboardingButtonVID_Aceptar {
    &:hover {
      background: var(--fondo-blanco);
      border: 1px solid var(--color-primary-main);
      color: var(--color-primary-main);
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 0px;
      width: 120px;
    }

    background: var(--color-primary-main);
    border: 1px solid var(--fondo-blanco);
    color: var(--fondo-blanco);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    width: 120px;
  }

  .div_centrado {
    display: none;
  }

  .onboardingButton__Welcome {
    background: var(--color-primary-main);
    border: 1px solid var(--fondo-blanco);
    color: var(--fondo-blanco);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    float: right;
    margin-bottom: 2% !important;
    width: 120px;
  }

  .congrats__button2 {
    background: var(--fondo-blanco);
    border: 1px solid var(--color-primary-main);
    color: var(--color-primary-main);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    width: 120px;
    float: left;
    margin-bottom: 2% !important;
    margin-left: 3%;

    &:hover {
      background: var(--color-primary-main);
      border: 1px solid var(--fondo-blanco);
      color: var(--fondo-blanco);
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 0px;
      width: 120px;
      float: left;
      margin-bottom: 2% !important;
      margin-left: 3%;
    }
  }

  .ant-layout-sider-children .logo {
    text-align: center !important;
    width: 65% !important;
    height: auto;
    margin-top: 62vh;
  }

  .Content__Apc {
    width: 77%;

    /* Arriba | Derecha | Abajo | Izquierda */
    margin: 24px 11% 0;
    overflow-y: scroll;
  }

  .div__terms {
    width: 77%;
    margin: 24px 11% 0;
    text-align: right;
  }

  .main__container {
    display: flex;
    justify-content: space-between;
    height: 40px;
    padding: 10px;
    margin-bottom: 20px;
  }

  .ant-layout-header {
    display: none;
    height: 64px;
    padding: 0 50px;
    line-height: 64px;
    background: #001529;
  }

  .Apc_className {
    padding: 5% 11% 0% 11%;
    text-align: left;
    font: normal normal 900 36px/45px Gilroy, sans-serif;
  }


  h1 {
    width: 96%;
    font-size: 46px;
  }

  .bg-mia {
    background-size: 10% 10% !important;
    background-repeat: no-repeat;
  }

  .bg-green-light .logo {
    display: none;
  }

  .bg-mia-congrats {
    background: var(--color-primary-main) no-repeat !important;
  }

  .isCitizenh1 {
    font-family: Gilroy, sans-serif;
    font-size: calc(16px + (800vw - 3200px) / 400) !important;
    font-weight: 300;
    line-height: 1.2em;
    margin-bottom: 15px;
  }

  .isWelcome__H1 {
    font-family: Gilroy, sans-serif;
    font-size: calc(16px + (800vw - 3200px) / 400) !important;
    font-weight: 300;
    line-height: 1.2em;
    margin-bottom: 0%;
    padding: 2px 57px 0px 57px;
  }

  .isCitizenp {
    color: #545454;
    font-size: 25px;
    margin: 0;
  }

  .onboardingButtonPdf {
    background: var(--color-primary-main);
    border: 1px solid var(--fondo-blanco);
    color: var(--fondo-blanco);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    margin: 2px 0em 2% 0em !important;
    width: 120px;
    order: 1;
  }

  .onboardingButtonBack {
    background: var(--fondo-blanco);
    border: 1px solid var(--color-primary-main);
    color: var(--color-primary-main);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    margin: 2px 0em 1em 0em !important;
    width: 120px;
    order: 1;

    &:hover {
      background: var(--color-primary-main);
      border: 1px solid var(--fondo-blanco);
      color: var(--fondo-blanco);
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 0px;
      margin: 2px 0em 1em 0em !important;
      width: 120px;
      order: 1;
    }
  }

  .main__containerPdf {
    display: flex;
    justify-content: space-between;
    height: 40px;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .finish__screen {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footerCongrats {
    display: none;
  }

  .finish__screen {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .congrats__button {
    background: var(--color-primary-main);
    border: 1px solid var(--fondo-blanco);
    color: var(--fondo-blanco);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    width: 20%;
    float: right;
  }

  .text-centered-Congrats {
    width: 97vw;
    margin: 2px 1em auto 1em;

    h2 {
      font-size: 3em;
      font-weight: 500;
      font-family: Gilroy, sans-serif;
      color: var(--color-primary-main);
      text-align: center;
    }

    p {
      text-align: center;
      padding-left: 5%;
      padding-right: 5%;
      justify-content: center !important;
      color: #545454;
      font-size: 25px;
      margin-bottom: 10px;
    }
  }

  .congrats__icon {
    width: 441px;
    text-align: center;
    justify-content: center;
    font-size: 1.9em;
  }

  .Congrats_footer {
    float: right;
  }

  .bg-mia-Congrats__mobile {
    background: var(--color-primary-main) no-repeat !important;
    height: 118px;

    .logo-cmf {
      text-align: center;
      width: 200px;
      margin-top: 30px;
    }
  }

  .onboardingButtonSignContract {
    background: var(--color-primary-main);
    border: 1px solid var(--fondo-blanco);
    color: var(--fondo-blanco) !important;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: normal;

    span {
      color: var(--fondo-blanco) !important;
      font-size: 14px !important;
      margin-right: 0px !important;
    }

    &:hover {
      text-align: center;
      text-transform: uppercase;
      border-radius: 0px;
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
      white-space: normal;
      background: var(--fondo-blanco);
      border: 1px solid var(--color-primary-main);
      color: var(--color-primary-main);

      span {
        color: var(--color-primary-main) !important;
        font-size: 14px !important;
        margin-right: 0px !important;
      }
    }
  }

  .main__containerPdf {
    display: flex;
    justify-content: space-between;
    height: 40px;
  }

  .onboardingButtonBack {
    background: var(--fondo-blanco);
    border: 1px solid var(--color-primary-main);
    color: var(--color-primary-main);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    margin: 2px 0em 1em 0em !important;
    width: 24vw;
    order: 1;

    &:hover {
      background: var(--color-primary-main);
      border: 1px solid var(--fondo-blanco);
      color: var(--fondo-blanco);
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 0px;
      margin: 2px 0em 1em 0em !important;
      width: 24vw;
      order: 1;
    }
  }

  .main__containerPdf .onboardingButtonPdf {
    background: var(--color-primary-main);
    border: 1px solid var(--fondo-blanco);
    color: var(--fondo-blanco);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    margin: 2px 0em 2% 0em !important;
    width: 23vw;
    order: 1;

    :hover {
      background: var(--fondo-blanco);
      border: 1px solid var(--color-primary-main);
      color: var(--color-primary-main);
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 0px;
      margin: 2px 0em 2% 0em !important;
      width: 23vw;
      order: 1;
    }
  }

  .ConoceMas__class {
    margin: 4%;
    margin-top: 30px;

    .Button__ConoceMas {
      width: 42vw;
      background: var(--color-primary-main);
      border: 1px solid var(--fondo-blanco);
      color: var(--fondo-blanco);
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 0px;
      margin: 2px 0em 2% 0em !important;
      margin: 24px;
    }
  }

  .salay__colum .label-muted {
    display: none;
  }

  .onboardingButtonVID {
    background: var(--fondo-blanco);
    border: 2px solid var(--color-primary-main);
    color: var(--color-primary-main);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    width: 120px;

    &:hover {
      background: var(--color-primary-main);
      border: 2px solid var(--fondo-blanco);
      color: var(--fondo-blanco);
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 0px;
      width: 120px;
    }
  }

  .onboardingButtonVID_Aceptar {
    background: var(--color-primary-main);
    border: 1px solid var(--fondo-blanco);
    color: var(--fondo-blanco);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    width: 120px;

    &:hover {
      background: var(--fondo-blanco);
      border: 1px solid var(--color-primary-main);
      color: var(--color-primary-main);
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 0px;
      width: 120px;
    }
  }

  .onboardingButton {
    background: var(--fondo-blanco);
    border: 1px solid var(--color-primary-main);
    color: var(--color-primary-main);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    margin: 2px 1em 1em -1em !important;
    width: 24vw;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 599px) {
  .main__containerPdf {
    display: grid;
    justify-content: space-between;
    height: 40px;
    margin: 0px 9px 47px 0px;
  }

  .onboardingButtonPdf {
    background: var(--color-primary-main);
    border: 1px solid var(--fondo-blanco);
    color: var(--fondo-blanco);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    margin: 2px 0em 2% 0em !important;
    width: 80vw;
    order: -1;
  }

  .onboardingButtonBack {
    background: var(--fondo-blanco);
    border: 1px solid var(--color-primary-main);
    color: var(--color-primary-main);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    margin: 2px 0em 1em 0em !important;
    width: 80vw;
    order: 1;

    &:hover {
      background: var(--color-primary-main);
      border: 1px solid var(--fondo-blanco);
      color: var(--fondo-blanco);
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 0px;
      margin: 2px 0em 1em 0em !important;
      width: 80vw;
      order: 1;
    }
  }

  .space {
    height: 1%;
  }

  .check__terms {
    padding: 39px 83px;
  }
}

@media only screen and (max-width: 600px) {
  .finish__screen {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .salay__colum .label-muted {
    display: none;
  }

  .padreEID {
    padding: 7px !important;
    flex-direction: column;
    display: flex;
    justify-content: center;
  }

  .footer__welcome2 {
    height: 15% !important;
  }

  .footer__welcome {
    position: absolute;
  }

  .ant-layout-footer {
    padding: 24px 0px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    background: var(--fondo-blanco);
  }

  .onboardingButtonVID {
    background: var(--fondo-blanco);
    border: 2px solid var(--color-primary-main);
    color: var(--color-primary-main);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    margin: 2px 1em 1em 0em !important;
    width: 100%;
  }

  .onboardingButtonVID_Aceptar {
    background: var(--color-primary-main);
    border: 1px solid var(--fondo-blanco);
    color: var(--fondo-blanco);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    margin: 1px 1em 2% 0em !important;
    width: 100%;
    order: -1;

    &:hover {
      background: var(--fondo-blanco);
      border: 1px solid var(--color-primary-main);
      color: var(--color-primary-main);
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 0px;
      margin: 1px 1em 2% 0em !important;
      width: 100%;
      order: -1;
    }
  }

  .onboardingButtonVID:hover {
    background: var(--color-primary-main);
    border: 2px solid var(--fondo-blanco);
    color: var(--fondo-blanco);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 0px;
    margin: 2px 1em 1em 0em !important;
    width: 100%;
  }

  .termsP {
    font-size: 14px;
    text-align: justify;
    padding-right: 1em;
  }

  .ant-layout-sider {
    width: 100%;
    height: 118px;
    text-align: center;
  }

  .ant-layout-header {
    width: 100%;
    height: 16%;
    line-height: 64px;
    background: #001529;
  }
}
