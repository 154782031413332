.sweetPagination .paginationUL .pageItem .activeButton {
    color: #f1f2f6;
    background-color: #247E00;
}

.sweetPagination .paginationUL .pageItem .pageButton:hover {
    color: #f1f2f6;
    background-color: #247E00;
    fill: #f1f2f6;
}

@media only screen and (max-width: 576px) {
    .sweetPagination .paginationUL .pageItem .pageButton {
        width: 30px;
        height: 30px;
        margin: 3px;
    }
}