@font-face {
    font-family: Gilroy;
    src: url("../../../assets/fonts/Gilroy-ExtraBold.woff2") format("woff2");
}

@font-face {
    font-family: Gilroy-Medium;
    src: url("../../../assets/fonts/Gilroy-Medium.woff2") format("woff2");
}

@font-face {
    font-family: Gilroy-Regular;
    src: url("../../../assets/fonts/Gilroy-Regular.woff2") format("woff2");
}

@font-face {
    font-family: Gilroy-Light;
    src: url("../../../assets/fonts/Gilroy-Light.woff2") format("woff2");
}

@font-face {
    font-family: Nunito;
    src: url("../../../assets/fonts/Nunito.woff2") format("woff2");
}

